import React, { useState } from "react";
import { Link } from "react-router-dom";

import step1 from "../assets/images/blog/business-details.png";
import step2 from "../assets/images/blog/business-photos.png";
import step3 from "../assets/images/blog/business-products.png";

export default function StepsTab() {
  const [activeIndex, setActiveIndex] = useState(1);
  return (
    <section className="realtive md:py-24 py-16">
      <div className="container relative">
        <div className="grid grid-cols-1 pb-6 text-center">
          <h3 className="font-semibold text-2xl leading-normal mb-4">
            A 3-step Journey of Getting Started
          </h3>

          <p className="text-slate-400 max-w-xl mx-auto">
            List Your Business in 3 Quick Steps
          </p>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1 mt-6 gap-6">
          <div className="lg:col-span-4 md:col-span-5">
            <div className="sticky top-20">
              <ul className="flex-column p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                <li role="presentation">
                  <button
                    className={`px-4 py-2 text-start text-base font-medium rounded-md w-full hover:text-teal-500 duration-500 ${
                      activeIndex === 1
                        ? "text-white bg-teal-500 hover:text-white"
                        : ""
                    }`}
                    onClick={() => setActiveIndex(1)}
                  >
                    <span className="block">Step 1</span>
                    <span className="text-lg mt-2 block">
                      Enter information about your business
                    </span>
                    <span className="block mt-2">
                      Give your business a name to get started
                    </span>
                  </button>
                </li>
                <li role="presentation">
                  <button
                    className={`px-4 py-2 text-start text-base font-medium rounded-md w-full mt-6 hover:text-teal-500 duration-500 ${
                      activeIndex === 2
                        ? "text-white bg-teal-500 hover:text-white"
                        : ""
                    }`}
                    onClick={() => setActiveIndex(2)}
                  >
                    <span className="block">Step 2</span>
                    <span className="text-lg mt-2 block">
                      Add photos to your business
                    </span>
                    <span className="block mt-2">
                      Add media information about your business
                    </span>
                  </button>
                </li>
                <li role="presentation">
                  <button
                    className={`px-4 py-2 text-start text-base font-medium rounded-md w-full mt-6 hover:text-teal-500 duration-500 ${
                      activeIndex === 3
                        ? "text-white bg-teal-500 hover:text-white"
                        : ""
                    }`}
                    onClick={() => setActiveIndex(3)}
                  >
                    <span className="block">Step 3</span>
                    <span className="text-lg mt-2 block">
                      Add the products or services you offer
                    </span>
                    <span className="block mt-2">
                      List the products or services you offer
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className="lg:col-span-8 md:col-span-7">
            <div
              id="myTabContent"
              className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md"
            >
              <div className={activeIndex === 1 ? "" : "hidden"}>
                <img
                  src={step1}
                  className="shadow dark:shadow-gray-700 rounded-md"
                  alt=""
                />

                <div className="mt-6">
                  <h5 className="text-lg font-medium">Business Details</h5>
                  <p className="text-slate-400 mt-4">
                    Enter information about your business, information like the
                    name of the business, the location you want to operate from,
                    the bank account you want to receive payment in.
                  </p>
                  <div className="mt-4">
                    <Link
                      to="https://blog.warneo.com/how-to-list-your-business-on-warneo-daf77bdf4b89"
                      target="_blank"
                      rel=""
                      className="text-teal-500"
                    >
                      Read More{" "}
                      <i className="mdi mdi-chevron-right align-middle"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className={activeIndex === 2 ? "" : "hidden"}>
                <img
                  src={step2}
                  className="shadow dark:shadow-gray-700 rounded-md"
                  alt=""
                />

                <div className="mt-6">
                  <h5 className="text-lg font-medium">Business Photos</h5>
                  <p className="text-slate-400 mt-4">
                    Add a required logo for your business Add a minimum of 4
                    other photos for your business Click next to proceed to the
                    next step.
                  </p>
                  <div className="mt-4">
                    <Link
                      to="https://blog.warneo.com/how-to-list-your-business-on-warneo-daf77bdf4b89"
                      target="_blank"
                      rel=""
                      className="text-teal-500"
                    >
                      Read More{" "}
                      <i className="mdi mdi-chevron-right align-middle"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className={activeIndex === 3 ? "" : "hidden"}>
                <img
                  src={step3}
                  className="shadow dark:shadow-gray-700 rounded-md"
                  alt=""
                />

                <div className="mt-6">
                  <h5 className="text-lg font-medium">Business Products</h5>
                  <p className="text-slate-400 mt-4">
                    If you are selling a product, or receiving orders, you can
                    list your different products or bundles as normal.
                  </p>
                  <div className="mt-4">
                    <Link
                      to="https://blog.warneo.com/how-to-list-your-business-on-warneo-daf77bdf4b89"
                      target="_blank"
                      rel=""
                      className="text-teal-500"
                    >
                      Read More{" "}
                      <i className="mdi mdi-chevron-right align-middle"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
