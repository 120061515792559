import {
  FiTrendingUp,
  FiDollarSign,
  FiMonitor,
  FiHome,
  FiStar,
  FiCheckCircle,
  FiFileText,
  FiCreditCard,
  FiMessageCircle,
  GiChart,
  FaSearch,
  FaShareSquare,
  FaClipboardList,
  FaFileAlt,
  FaUserFriends,
  FaServer,
} from "../assets/icons/vander";

import client1 from "../assets/images/warneo/testimonials/rhidtech.jpg";
import client2 from "../assets/images/warneo/testimonials/saint-luxe.jpg";
import client3 from "../assets/images/warneo/testimonials/jb.jpg";
// import client4 from "../assets/images/team/04.jpg";
// import client5 from "../assets/images/team/05.jpg";
// import client6 from "../assets/images/team/06.jpg";

import blog1 from "../assets/images/blog/01.jpg";
import blog2 from "../assets/images/blog/02.jpg";
import blog3 from "../assets/images/blog/03.jpg";

import company1 from "../assets/images/team/amazon.svg";
import company2 from "../assets/images/team/google.svg";
import company3 from "../assets/images/team/lenovo.svg";
import company4 from "../assets/images/team/paypal.svg";
import company5 from "../assets/images/team/shopify.svg";
import company6 from "../assets/images/team/spotify.svg";

export const servicesForBusiness = [
  {
    icon: FiTrendingUp,
    title: "Grow Your Business",
    desc: "Watch your business grow 10X faster with Warneo.",
  },
  {
    icon: GiChart,
    title: "Get More Customers",
    desc: "Reach  a wider audience when you subscribe to any of our pakcages.",
  },
  {
    icon: FiDollarSign,
    title: "Drive More Sales",
    desc: " Generate higher revenue through our sales-focused solutions.",
  },
  {
    icon: FiMonitor,
    title: "Custom Web Page",
    desc: "Create a unique online presence that reflects your brand & resonates with your target audience.",
  },
  {
    icon: FaShareSquare,
    title: "Social Media Links",
    desc: "Harness the power of social media to connect with your customers.",
  },
  {
    icon: FaSearch,
    title: "Free SEO",
    desc: " Identify areas for improvement & optimize your website for better search engine visibility.",
  },
  {
    icon: FiStar,
    title: " Ratings and Reviews",
    desc: "Build trust & credibility by showcasing positive customer feedback & ratings.",
  },
  {
    icon: FiCreditCard,
    title: " Payment Integration",
    desc: "Accept secure payments directly through your website, offering a convenient checkout experience for your customers.",
  },
  {
    icon: FaClipboardList,
    title: "Order Management",
    desc: "Simplify order processing, track fulfillment stages, & manage your inventory efficiently.",
  },
  {
    icon: FaFileAlt,
    title: "Transaction Logs",
    desc: "Maintain a detailed record of all financial transactions for better financial tracking & analysis.",
  },
  {
    icon: FiFileText,
    title: "Business Receipts",
    desc: "Get custom-tailored receipts for your business orders",
  },
  {
    icon: FiMonitor,
    title: "Business Statistics",
    desc: "Gain valuable insights into website traffic, customer behavior, & campaign performance",
  },
  {
    icon: FaUserFriends,
    title: "Access to Client Base",
    desc: "Manage your customer information effectively & reach out to them for targeted marketing efforts.",
  },
  {
    icon: FaServer,
    title: "Save Cost in Website Hosting",
    desc: "With Warneo, You don't have to worry about paying to get your website hosted.",
  },
];
export const servicesForCustomers = [
  {
    icon: FiHome,
    title: "One Platform to Find All Your Needs",
    desc: " Simplify your life with everything you need in one convenient location.",
  },
  {
    icon: FiStar,
    title: "Review Businesses & Give Ratings",
    desc: "Share your experience an& help others make informed decisions.",
  },
  {
    icon: FiCheckCircle,
    title: "View Your Orders",
    desc: "Easily track your purchases & manage order details in one place.",
  },
  {
    icon: FiFileText,
    title: "View Your Reciepts",
    desc: "Access your digital receipts for effortless record-keeping & expense management.",
  },
  {
    icon: FiCreditCard,
    title: "Make Payment Online",
    desc: " Securely pay for your purchases directly through the platform without any hassle.",
  },
  {
    icon: FiMessageCircle,
    title: "Contact & Chat to Businesses",
    desc: "Connect with businesses directly through chat for efficient communication.",
  },
];

export const testimonials = [
  {
    image: client1,
    name: "Rhidtech",
    title: "WordPress Development & Graphics designing",
    desc: '" Warneo has helped me reach more audience, and I made more money using the app."',
  },
  {
    image: client2,
    name: "Saint Luxe",
    title: "Clothing brand for street fashion",
    desc: '"  The platform has helped me expand my sales so I’m grateful for this incredible platform. "',
  },
  {
    image: client3,
    name: "JB Digitals",
    title: "Social media and content marketing",
    desc: '"Warneo has helped my business on so many ways, I have a larger customer base and the landing page helps a lot."',
  },
  // {
  //   image: client4,
  //   name: "Smith Vodka",
  //   title: "Manager",
  //   desc: '" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts "',
  // },
  // {
  //   image: client5,
  //   name: "Cristino Murfi",
  //   title: "Manager",
  //   desc: '" There is now an abundance of readable dummy texts. These are usually used when a text is required"',
  // },
  // {
  //   image: client6,
  //   name: "Cristino Murfi",
  //   title: "Manager",
  //   desc: '" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero "',
  // },
];

export const blogData = [
  {
    image: blog1,
    title: "Design your apps in your own way",
    desc: "This is required when, for example, the final text is not yet available.",
    date: "13th March 2023",
  },
  {
    image: blog2,
    title: "How apps is changing the IT world",
    desc: "This is required when, for example, the final text is not yet available.",
    date: "5th May 2023",
  },
  {
    image: blog3,
    title: "Smartest Applications for Business",
    desc: "This is required when, for example, the final text is not yet available.",
    date: "19th June 2023",
  },
];

export const companyLogo = [
  company1,
  company2,
  company3,
  company4,
  company5,
  company6,
];
