import React, { useState, useEffect } from "react";

const url = `https://graph.instagram.com/me/media?fields=id,caption,media_url,timestamp,media_type,permalink&access_token=${process.env.REACT_APP_INSTAGRAM_TOKEN_KEY}`;

export default function Posts() {
  const [instagramPosts, setInstagramPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`API request failed with status ${response.status}`);
        }
        const data = await response.json();
        setInstagramPosts(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <section className="relative md:py-24 py-16" id="blog">
      <div className="container relative">
        <div className="grid grid-cols-1 pb-6 text-center">
          <h3 className="font-semibold text-2xl leading-normal mb-4">
            Warneo Latest Posts
          </h3>

          <p className="text-slate-400 max-w-xl mx-auto">
            Check out our latest posts from our social media
          </p>
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
          {instagramPosts
            .filter((post) => post.media_type !== "VIDEO")
            .slice(0, 6)
            .map((post) => (
              <div key={post.id} className="group relative overflow-hidden">
                <a
                  href={post.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                    <img
                      src={post.media_url}
                      className="group-hover:scale-110 duration-500"
                      alt={post.caption || "Instagram Post"}
                      title={post.caption || "Instagram Post"}
                    />
                    <div style={{ display: "none", visibility: "hidden" }}>
                      {post.caption}
                    </div>
                  </div>
                </a>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}
