import React from "react";
import googlePlay from "../assets/images/get-warneo/google-play.svg";
import appStore from "../assets/images/get-warneo/apple.jpeg";
import phoneMockup from "../assets/images/get-warneo/phone-mockup.png";
import logo from "../assets/images/logo-dark.png";

export default function GetStarted() {
  return (
    <section className="relative bg-slate-50 dark:bg-slate-800 my-14">
      <div className="container relative bg-gradient-to-tr from-teal-500 to-teal-700 rounded-3xl grid grid-cols-1 sm:grid-cols-2 py-8">
        <div className="flex flex-col gap-2 justify-center py-6 px-2 sm:py-0 sm:px-0">
          <img src={logo} alt="Warneo-logo" className="w-36 mb-8" />
          <h3 className="font-bold text-3xl leading-normal text-white">
            Get Started with Warneo
          </h3>
          <p className="text-lg text-white">
            With your mobile device, get Warneo and have access to different
            services at your finger tips.
          </p>
          <main className="flex mt-4 gap-6 ">
            <div className=" bg-white dark:bg-white dark:text-black rounded-lg py-0.5 px-4  ">
              <a
                href="https://play.google.com/store/apps/details?id=com.ife.warneo&pli=1"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center  gap-2"
              >
                <img
                  src={googlePlay}
                  alt=""
                  className="w-6 h-6 sm:w-8 sm-h-8 md:w-10 md:h-10"
                />
                <p className="uppercase text-base">
                  get it on <br />{" "}
                  <span className="capitalize font-bold text-base sm:text-xl md:text-2xl lg:text-2xl">
                    google play
                  </span>
                </p>
              </a>
            </div>

            <div className=" bg-white dark:bg-white dark:text-black rounded-lg  py-0.5 px-4 ">
              <a
                href="https://apps.apple.com/us/app/warneo-find-vendors-near-you/id1611952783"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2"
              >
                <img
                  src={appStore}
                  alt=""
                  className="w-6 h-6 sm:w-8 sm-h-8 md:w-10 md:h-10"
                />
                <p>
                  Download on the <br />{" "}
                  <span className="capitalize font-bold text-base sm:text-xl md:text-2xl lg:text-2xl">
                    app store
                  </span>
                </p>
              </a>
            </div>
          </main>
        </div>
        <main className="hidden-on-mobile absolute md:left-[50%] -top-[33%] lg:-top[45%]">
          <img src={phoneMockup} alt="Get started with Warneo" />
        </main>
      </div>
    </section>
  );
}
