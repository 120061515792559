import "./App.css";
import "./assets/noir-pro/styles.css";
import "./assets/css/tailwind.css";
import "./assets/css/materialdesignicons.min.css";
import { Navigate, Route, Routes } from "react-router-dom";
// import Index from "./pages";
// import IndexTwo from "./pages/index-two";
import Home from "./pages/Home";
import { useEffect } from "react";
// import IndexFour from "./pages/index-four";
// import IndexFive from "./pages/index-five";
// import IndexSix from "./pages/index-six";
// import IndexSeven from "./pages/index-seven";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* Redirect */}
      <Route path="/old-url" element={<Navigate to="/new-url" replace />} />
      {/* 404 Not Found */}
      <Route path="*" element={<RedirectToDifferentDomain />} />
      {/* <Route path="*" element={<Navigate to="https://directory.warneo.com" state={{ from: location }} replace />} /> */}
      {/* <Route path="/redirect/:param" element={<RedirectWithParam />} /> */}

      {/* <Route path="/index-one" element={<Index />} />
      <Route path="/index-two" element={<IndexTwo />} /> */}
      {/* <Route path="/index-three" element={<Home />} /> */}
      {/* <Route path="/index-four" element={<IndexFour />} />
      <Route path="/index-five" element={<IndexFive />} />
      <Route path="/index-six" element={<IndexSix />} />
      <Route path="/index-seven" element={<IndexSeven />} /> */}
    </Routes>
  );
}

function RedirectToDifferentDomain() {
  // Redirecting to a directory.warneo.com to support old links domain
  useEffect(() => {
    window.location.href = `https://directory.warneo.com${window.location.pathname}`;
  }, []);

  return null; // or any loading indicator
}

export default App;
